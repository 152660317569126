<template>
    <div>
        <MobileContainersAppHeader />
        <div :class="['content-body']">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
const { locale, setLocale } = useI18n();

/**
 * Default layout
 */

const { status: authStatus, data: authData } = useAuth();
const interval = ref<number | null>(null);

onMounted(async () => {
    await initialize(authStatus.value === "authenticated");

    attemptToSwitchLocale();
});

async function initialize(loggedIn: boolean) {
    if (loggedIn) {
        if (authData.value) {
            await attemptToSwitchLocale();
        }
    } else {
        if (interval.value) {
            clearInterval(interval.value);
        }
    }
}

async function attemptToSwitchLocale() {
    if (authStatus.value === "authenticated" && authData.value) {
        if (locale !== authData.value?.language) {
            await setLocale(authData.value?.language);
        }
    }
}
</script>
